import Vue from "vue";
import VueRouter from "vue-router";
import LoginPage from "../views/LoginPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: LoginPage,
    meta: { requiresAuth: false },
  },
  {
    path: "/book-slot",
    name: "BookSlot",
    component: () =>
      import(/* webpackChunkName: "BookSlot" */ "../views/BookSlot.vue"),
    meta: { requiresAuth: true }, // Add a meta field to indicate the route requires authentication
  },
  {
    path: "/bookings",
    name: "Bookings",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (bookonline.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "bookonline" */ "../views/BookingPage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/time-sheet",
    name: "TimeSheet",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (bookonline.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "bookonline" */ "../views/TimeSheet.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/cake-sheet",
    name: "CakeSheet",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (bookonline.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "bookonline" */ "../views/CakeSheet.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/analytics",
    name: "Analytics",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (analytics.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "analytics" */ "../views/AnalyticsPage.vue"),
    meta: { requiresAuth: true, requiresAdminAccess: true },
  },
];

// const defaultRoute = "Bookings"; // Set the default route for redirection

const router = new VueRouter({
  routes,
  mode: "history",
  base: "/",
});

// router.beforeEach((to, from, next) => {
//   const token = localStorage.getItem("token");
//   const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
//   // const requiresAdminAccess = to.matched.some(
//   //   (record) => record.meta.requiresAdminAccess
//   // );

//   if (requiresAuth && !token) {
//     // Redirect to Login if trying to access an authenticated route without authentication
//     next({ name: "Login" });
//   } else if (!requiresAuth && token) {
//     // Redirect to default route if trying to access a non-authenticated route while logged in
//     next({ name: defaultRoute });
//   } else if (!router.options.routes.find((route) => route.path === to.path)) {
//     // Redirect to default route if the requested route is not defined
//     next({ name: defaultRoute });
//   } else {
//     // if (requiresAdminAccess) {
//     //
//     //   next({ name: defaultRoute });
//     // }
//     // else
//     // Continue with navigation
//     next();
//   }
// });

router.beforeEach((to, _from, next) => {
  const isAuthenticated = !!localStorage.getItem("token");
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth && !isAuthenticated) {
    next({ name: "Login" });
  } else {
    next();
  }
});

export default router;
