<template>
  <v-row justify="center" class="fill-height">
    <v-col cols="12" sm="5" lg="3" md="4" align-self="center">
      <v-form @submit.prevent="login">
        <v-text-field
          placeholder="Username"
          outlined
          dense
          color="secondary"
          v-model="formData.username"
          dark
        ></v-text-field>
        <v-text-field
          placeholder="Password"
          outlined
          dense
          color="secondary"
          type="password"
          v-model="formData.password"
          dark
        ></v-text-field>
        <v-btn color="secondary" class="primary--text" type="submit">Login</v-btn>
      </v-form>
    </v-col>
  </v-row>
</template>
<script>
import { login } from "@/services/user/user.service";
import { jwtDecode } from "jwt-decode";

export default {
  nam: "LoginPage",
  data() {
    return {
      formData: {
        username: "",
        password: "",
      },
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  created() {},
  watch: {},
  computed: {},
  methods: {
    async login() {
      try {
        const response = await login({
          username: this.formData.username,
          password: this.formData.password,
        });

        if (response.data.success) {
          const token = response.data.token;

          // Decode the JWT token to get user data
          const decodedToken = jwtDecode(token);
          // Store user data in Vuex store
          this.$store.commit("setUserData", decodedToken);

          // Store the token in localStorage
          localStorage.setItem("token", token);

          // Set the isLoggedIn flag to true
          this.$store.commit("setIsLoggedIn", true);

          // Redirect to the desired route (BookSlot in this case)
          this.$router.push({ name: "BookSlot" });
        } else {
          this.$store.commit("setIsLoggedIn", false);
        }
      } catch (e) {
        console.error(e);
        this.$store.commit("setIsLoggedIn", false);
      }
    },
  },
};
</script>
<style></style>
