<template>
  <v-container fluid>
    <v-row no-gutters class="header">
      <v-col cols="10" lg="4" md="4" align-self="center">
        <div class="logo">
          <div class="logo-text text-left">
            <router-link :to="{ name: 'BookSlot' }" class="secondary--text">
              <h3>Happy Harbour</h3>
              <h5>
                {{ getSelectedLocationName }}
              </h5>
            </router-link>
          </div>
        </div>
      </v-col>
      <v-col
        cols="8"
        lg="8"
        md="8"
        align-self="center"
        class="d-none d-lg-block d-md-block"
      >
        <app-menu v-if="isLoggedIn" @closeDrawer="drawer = false"></app-menu>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AppMenu from "@/components/Menus.vue";
import { mapGetters } from "vuex";

export default {
  name: "AppHeader",
  components: {
    AppMenu,
  },
  created() {
    console.log("Header", this.isLoggedIn);
    console.log("getSelectedLocation", this.getSelectedLocation);
  },
  computed: {
    ...mapGetters(["isLoggedIn","getSelectedLocation", "getSelectedLocationName"]),
  },
  mounted() {},
  methods: {},
};
</script>
<style>
.header .logo .logo-text {
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
}

.header .logo-text a {
  font-weight: 600;
  text-decoration: none;
}
</style>
