import store from "@/store";
import resourcesServer from "@/services/config/resourcesServerConfig";

export const bookSlot = (data) => {
  return resourcesServer.post("/booking/book-now-admin", data);
};

export const getAllBookings = () => {
  return resourcesServer.get(
    `/booking/get-all-bookings/${store.getters.getSelectedLocation}`
  );
};

export const getBookings = (date) => {
  return resourcesServer.get(
    `/booking/get-bookings/${store.getters.getSelectedLocation}/${date}`
  );
};

export const viewTimeSheet = (data) => {
  return resourcesServer.post("/booking/view-time-sheet", data);
};

export const viewCakeSheet = (data) => {
  return resourcesServer.post("/booking/view-time-sheet", data);
};

export const viewAllSlots = (locationId) => {
  return resourcesServer.get(`/booking/view-all-slots/${locationId}`);
};

export const getCakeDD = () => {
  return resourcesServer.get(`/booking/get-cake-dd/${store.getters.getSelectedLocation}`);
};

export const getSpecialDecorDD = () => {
  return resourcesServer.get(`/booking/get-special-decor-dd/${store.getters.getSelectedLocation}`);
};

export const getOccasionDD = () => {
  return resourcesServer.get(`/booking/get-occasion-dd/${store.getters.getSelectedLocation}`);
};

export const getComboDD = () => {
  return resourcesServer.get(`/booking/get-combo-dd/${store.getters.getSelectedLocation}`);
};

export const viewAllplans = () => {
  return resourcesServer.get(
    `/booking/view-all-plans/${store.getters.getSelectedLocation}`
  );
};

export const getBookingById = (data) => {
  return resourcesServer.post("/booking/get-booking-by-id", data);
};

export const acceptBooking = (data) => {
  return resourcesServer.post("/booking/admin-accept-booking", data);
};

export const rejectBooking = (data) => {
  return resourcesServer.post("/booking/admin-reject-booking", data);
};

export const updateBooking = (data) => {
  return resourcesServer.post(`/booking/update-booking`, data);
};

export const getAllLocationsByUserId = () => {
  return resourcesServer.get(`/booking/get-all-locations-by-user-id`);
};
